import { useQuery } from '@tanstack/react-query';
import { Button, Form } from 'antd';
import DataTable from 'common/ui/DataTable';
import MetaDecorator from 'components/MetaDecorator';
import PageTitle from 'components/PageTitle';
import StatementFilterForm from 'components/statement/StatementFilterForm';
import { Link, useParams, useSearchParams } from 'react-router-dom';

export const StatementPage = ({
  title,
  params,
  ItemCard,
  statementColumns,
  statementApiCall,
  statementUrl,
  FilterForm,
  backUrl,
  ...props
}) => {
  const [form] = Form.useForm();
  const urlParams = useParams();
  const [baseSearchParams] = useSearchParams();
  const searchParams = Object.fromEntries(baseSearchParams);
  const payload = { ...urlParams, ...searchParams, ...params };

  const { data = {}, isLoading } = useQuery({
    queryKey: [statementApiCall.list.queryKey, payload],
    queryFn: () => statementApiCall.list.queryFn(payload),
  });
  const { items = [], paging, meta: metaData, stats } = data;

  const onFiltering = values => {
    var values = Object.fromEntries(
      Object.entries(values).filter(([k, v]) => v)
    );
  };

  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle title={title} showBreadscrumb={false} backUrl={backUrl} />
      <div className="flex items-center justify-between">
        {FilterForm ? (
          <FilterForm
            form={form}
            onFinish={onFiltering}
            submiting={isLoading}
            initialValues={searchParams}
          />
        ) : (
          <StatementFilterForm
            form={form}
            onFinish={onFiltering}
            submiting={isLoading}
            initialValues={searchParams}
          />
        )}
        {statementUrl ? (
          <Button type="primary">
            <Link to={statementUrl}>Statement</Link>
          </Button>
        ) : null}
      </div>
      {ItemCard ? <ItemCard {...stats} {...metaData?.stats} /> : null}
      <DataTable
        rowKey="id"
        columns={statementColumns(props)}
        dataSource={items}
        totalItems={paging?.total_items}
        currentPage={paging?.current_page || paging?.page}
        defaultCurrent={1}
        defaultPageSize={paging?.page_size || paging?.per_page}
        pageSize={paging?.page_size || paging?.per_page}
        // dataFetcher={fetchItems}
        loading={isLoading}
        //filters keep filter params when go to another page
        filters={{ ...urlParams, ...searchParams, ...params }}
        {...props}
      />
    </>
  );
};
