import { Button, Form, Input, Space, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { compact } from 'lodash/fp';
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';

import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { getUser } from 'utils/auth';
import { normalizeFile } from 'utils/formData';
import { usePostApi } from 'common/reduxutils';
import DebounceSelect from 'common/ui/DebouceSelect';
import apiCall from 'apiCalls/business';
import expenseTypeApiCall from 'apiCalls/expenseType';

const isEmptyArray = v => Array.isArray(v) && v.length === 0;
const imageToFileList = image =>
  image
    ? [
        {
          uid: image,
          url: image,
          status: 'done',
        },
      ]
    : [];

const ItemForm = ({ form, submiting, onFinish, initialValues = {}, name }) => {
  const { id } = useParams();
  const user = getUser();

  const {
    post: deleteItem,
    isLoading: isDeleting,
    error: deleteItemError,
  } = usePostApi(apiCall.delete);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const isOwner = initialValues.owner === user.id;
  const canDeleteBusiness = id && isOwner;

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'business_form'}
      onFinish={({ logo, stamp, signature, ...values }) => {
        onFinish(
          {
            ...values,
            logo: isEmptyArray(logo) ? null : logo,
            stamp: isEmptyArray(stamp) ? null : stamp,
            signature: isEmptyArray(signature) ? null : signature,
          },
          compact([
            isEmptyArray(logo) ? null : 'logo',
            isEmptyArray(stamp) ? null : 'stamp',
            isEmptyArray(signature) ? null : 'signature',
          ])
        );
      }}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: 'Please enter display name' }]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        name="company_name"
        label="Company Name"
        rules={[
          {
            required: true,
            message: 'Please enter company name to display in invoices',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="registration_number" label="Registration Number">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="email" label="Email">
        <Input type="email" placeholder="" />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Phone"
        rules={[
          {
            pattern: /^\+?\s*\d{1,3}([\s-]*\d{1,4}){2,4}$/,
            message: 'Please enter a valid phone number',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="address1" label="Address Line 1">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="address2" label="Address Line 2">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="address3" label="Address Line 3">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="bank_notes" label="Bank Notes">
        <Input.TextArea rows={3} placeholder="" />
      </Form.Item>

      <Form.Item name="tax_number" label="TIN">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="tax_expense_type" label="Tax Expense Type">
        <DebounceSelect
          apiCall={expenseTypeApiCall.list}
          initialOption={initialValues.expense_type}
          params={{ business_id: id }}
          placeholder="Select tax expense type"
          fieldNames={{ value: 'id', label: 'name' }}
        />
      </Form.Item>

      <Form.Item
        name="logo"
        label="Logo"
        valuePropName="fileList "
        getValueFromEvent={normalizeFile}
      >
        <Upload.Dragger
          listType="picture"
          beforeUpload={() => false}
          defaultFileList={imageToFileList(initialValues.logo)}
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item
        name="stamp"
        label="Stamp"
        valuePropName="fileList "
        getValueFromEvent={normalizeFile}
      >
        <Upload.Dragger
          listType="picture"
          beforeUpload={() => false}
          defaultFileList={imageToFileList(initialValues.stamp)}
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item
        name="signature"
        label="Signature"
        valuePropName="fileList "
        getValueFromEvent={normalizeFile}
      >
        <Upload.Dragger
          listType="picture"
          beforeUpload={() => false}
          defaultFileList={imageToFileList(initialValues.signature)}
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Space>
          <Button type="primary" htmlType="submit" loading={submiting}>
            Save
          </Button>
          {canDeleteBusiness && (
            <Button
              danger
              loading={isDeleting}
              onClick={() => {
                if (confirm('Are you sure?')) {
                  deleteItem({ id });
                }
              }}
            >
              Delete
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
