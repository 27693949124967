import { useParams } from 'react-router-dom';
import React from 'react';

import { CreatePage, EditPage } from 'common/ui/AdminCRUD/page';
import apiCall from 'apiCalls/business';

import ItemForm from './Form';

export const BusinessEditPage = props => {
  const { id: business_id } = useParams();

  return (
    <EditPage
      title="Edit Businesses"
      apiCall={apiCall}
      listUrl={`/${business_id}`}
      ItemForm={ItemForm}
      resourceName=""
      {...props}
    />
  );
};

export const BusinessCreatePage = props => (
  <CreatePage
    title="New Businesses"
    apiCall={apiCall}
    listUrl={`/${business_id}`}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);
