import {
  BankOutlined,
  NumberOutlined,
  MailOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import { Button, Card, Descriptions, Space } from 'antd';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import MetaDecorator from 'components/MetaDecorator';
import apiCall from 'apiCalls/business';
import useTenant from 'components/use-tenant';

const useBusinessQuery = business_id => {
  return useQuery({
    queryKey: [apiCall.detail.queryKey, business_id],
    queryFn: () => {
      if (business_id) {
        return apiCall.detail.queryFn({ id: business_id });
      }

      return Promise.resolve('');
    },
  });
};

const BusinessHome = ({ name }) => {
  const { business_id } = useTenant();
  const { data = {}, isLoading } = useBusinessQuery(business_id);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <MetaDecorator title={'Home'} />
      <div className="mx-auto max-w-5xl p-4 md:p-8 mb-6">
        <div className="mb-8 text-center">
          <h1 className="mb-2 text-4xl font-bold tracking-tight text-gray-900">
            Hi {name} 👋
          </h1>
          <p className="text-xl text-gray-600">Welcome to {data.name}</p>
        </div>

        <Card className="shadow-lg [&_.ant-card-body]:p-0" bordered={false}>
          <Descriptions
            bordered
            column={1}
            size="large"
            labelStyle={{
              background: '#f8fafc',
              fontWeight: 500,
              width: '200px',
            }}
            contentStyle={{
              background: '#ffffff',
              minWidth: '240px',
            }}
          >
            <Descriptions.Item
              label={
                <span className="flex items-center gap-2">
                  <BankOutlined className="text-lg" />
                  Company
                </span>
              }
            >
              <strong>{data.company_name}</strong>
            </Descriptions.Item>

            <Descriptions.Item
              label={
                <span className="flex items-center gap-2">
                  <NumberOutlined className="text-lg" />
                  Entity
                </span>
              }
            >
              <div className="grid grid-cols-2">
                <div className="border-r">
                  <strong>TIN:</strong> {data.tax_number}
                </div>
                <div className="border-r">
                  <strong>Reg No:</strong> {data.registration_number}
                </div>
              </div>
            </Descriptions.Item>

            <Descriptions.Item
              label={
                <span className="flex items-center gap-2">
                  <MailOutlined className="text-lg" />
                  Contact Detail
                </span>
              }
            >
              <div className="grid grid-cols-2">
                <div className="border-r">
                  <strong>Email:</strong> {data.email}
                </div>
                <div className="border-r">
                  <strong>Tel:</strong> {data.phone}
                </div>
              </div>
            </Descriptions.Item>

            <Descriptions.Item
              label={
                <span className="flex items-center gap-2">
                  <EnvironmentOutlined className="text-lg" />
                  Address
                </span>
              }
            >
              <div className="space-y-1">
                <div>{data.address1}</div>
                <div>{data.address2}</div>
                <div>{data.address3}</div>
              </div>
            </Descriptions.Item>
          </Descriptions>
        </Card>

        <Space className="flex justify-end mt-4">
          <Link to={`/businesses/${business_id}/settings`}>
            <Button type="primary">Edit</Button>
          </Link>
        </Space>
      </div>
    </>
  );
};

export default BusinessHome;
